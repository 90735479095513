<template>
    <div class="overflow-x-scroll">
        <ag-grid-vue
            class="ag-theme-alpine flex-grow opacity_transition"
            :columnDefs="columns"
            :suppressColumnVirtualisation="true"
            :suppressRowVirtualisation="true"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
            @cellEditingStopped="cellEditRequest"
            domLayout="autoHeight"

            :rowData="rowList">
        </ag-grid-vue>        

        <!-- <table>
            <thead>
                <tr>
                    <th scope="col">Функциональная группа</th>
                    <th></th>
                    <th></th>
                    <th scope="col" rowspan="5">Наименование расходов</th>
                    <th scope="col" rowspan="5">Сумма изменений (+,-), всего</th>
                    <th scope="col"  colspan="12" rowspan="2">В том числе по месяцам (в текущем месяце - изменения с нарастающим итогом за период с начала год, в последующие месяцы - изменения помесячные)</th>         
                </tr>
                <tr>
                    <th></th>
                    <th scope="col">Администратор</th>
                    <th></th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th scope="col" tabindex="-1">Программа</th>
    
                    <th scope="col" tabindex="-1" rowspan="3">Январь</th>
                    <th scope="col" tabindex="-1" rowspan="3">Февраль</th>
                    <th scope="col" tabindex="-1" rowspan="3">Март</th>
                    <th scope="col" tabindex="-1" rowspan="3">Апрель</th>
                    <th scope="col" tabindex="-1" rowspan="3">Май</th>
                    <th scope="col" tabindex="-1" rowspan="3">Июнь</th>
                    <th scope="col" tabindex="-1" rowspan="3">Июль</th>
                    <th scope="col" tabindex="-1" rowspan="3">Август</th>
                    <th scope="col" tabindex="-1" rowspan="3">Сентябрь</th>
                    <th scope="col" tabindex="-1" rowspan="3">Октябрь</th>
                    <th scope="col" tabindex="-1" rowspan="3">Ноябрь</th>
                    <th scope="col" tabindex="-1" rowspan="3">Декабрь</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th colspan="17">Плановые назначения</th> 
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">Chris</th>
                    <td>HTML tables</td>
                    <td>22</td>
                </tr>
                <tr>
                    <th scope="row">Dennis</th>
                    <td>Web accessibility</td>
                    <td>45</td>
                </tr>
                <tr>
                    <th scope="row">Sarah</th>
                    <td>JavaScript frameworks</td>
                    <td>29</td>
                </tr>
                <tr>
                    <th scope="row">Karen</th>
                    <td>Web performance</td>
                    <td>36</td>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th scope="row" colspan="2">Average age</th>
                    <td>33</td>
                </tr>
            </tfoot>
        </table> -->
    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridVue } from "ag-grid-vue";

export default {
    components: {
        AgGridVue,
    },
    data() {
        return {
            overlayNoRowsTemplate:null,
            // gridApi: null,
            // gridColumnApi: null,
            columns: [
                { 
                    headerName: 'Функциональная группа', 
                    editable: true,
                    field: 'test',  
                },
                { 
                    headerName: 'Администратор', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Программа', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Наименование расходов', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Сумма изменений (+,-), всего', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Январь', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Февраль', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Март', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Апрель', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Май', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Июнь', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Июль', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Август', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Сентябрь', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Октябрь', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Ноябрь', 
                    editable: true, 
                    field: 'test',  
                },
                { 
                    headerName: 'Декабрь', 
                    editable: true, 
                    field: 'test',  
                },
            ],
            rowList: [
                { test: 'nothing' },
                { test: 'nothing' },
                { test: 'nothing' },
                { test: 'nothing' },
            ]
        }
    },
    created() {
        this.overlayNoRowsTemplate = `<span style="padding: 10px;">Нет данных</span>`
    },
    methods: {
        cellEditRequest(params) {
            console.log(params)
            this.$message.info(`Загружаем данные по коду: ${params.newValue}`)
            // console.log()
        },
    }
}
</script>

<style lang="scss" scoped>
th, td {
    &:focus {
        background: #333;
    }
    border: 1px solid #333;
}
</style>