<template>
    <a-drawer
        :title="$t('reports.add_report')"
        :visible="visible"
        class="drawer"
        @close="closeDrawer"
        destroyOnClose
        :zIndex="zIndex"
        :width="drawerWidth"
        :afterVisibleChange="afterVisibleChange"
        placement="right">
        <div class="drawer_body">
            <a-form-model 
                class="form row"
                :model="form"
                :rules="rules" 
                layout="vertical">
                <div class="panel">
                    <div class="panel_header w-full flex justify-between">
                        <div>
                            <div class="panel_title">Отчёты</div>
                            <div class="mt-2 panel_description">Выберите из списка форму отчета</div>
                        </div>
                        <div class="panel_step">шаг 1</div>
                    </div>
                    <a-form-model-item 
                        class="form_item row"
                        prop="format"
                        label="Формат отчета">
                        <a-input
                            class="custom_input"
                            v-model="form.format"
                            placeholder="Выберите формат отчета" />
                    </a-form-model-item>
                    <div class="grid_cols_2 row">
                        <a-form-model-item 
                            class="form_item"
                            prop="format"
                            label="Дата формирования отчета">
                            <a-input
                                class="custom_input"
                                v-model="form.format"
                                placeholder="Укажите дату формирования отчета" />
                        </a-form-model-item>

                        <a-form-model-item 
                            class="form_item"
                            prop="format"
                            label="Номер документа в вашей учетной системе">
                            <a-input
                                class="custom_input"
                                v-model="form.format"
                                placeholder="Введите номер документа в вашей учетной системе" />
                        </a-form-model-item>
                    </div>
                </div>

                <div class="panel">
                    <div class="panel_header w-full flex justify-between">
                        <div>
                            <div class="panel_title">Формирование отчета</div>
                            <div class="mt-2 panel_description">Заполните заявку на изменение плана финансирования по платежам</div>
                        </div>
                        <div class="panel_step">шаг 2</div>
                    </div>
                    <ConsolidationTable />
                </div>

                <div class="panel">
                    <div class="panel_header w-full flex justify-between">
                        <div>
                            <div class="panel_title">Обоснование</div>
                            <div class="mt-2 panel_description">Выберите из списка форму отчета</div>
                        </div>
                        <div class="panel_step">шаг 3</div>
                    </div>
                    <div class="grid_cols_3 row">
                        <a-form-model-item 
                            class="form_item"
                            prop="format"
                            label="Укажите программу для обоснования">
                            <a-input
                                class="custom_input"
                                v-model="form.format"
                                placeholder="Укажите программу для обоснования" />
                        </a-form-model-item>

                        <a-form-model-item 
                            class="form_item"
                            prop="format"
                            label="Выберите обоснование">
                            <a-input
                                class="custom_input"
                                v-model="form.format"
                                placeholder="Выберите обоснование" />
                        </a-form-model-item>
                        <a-button
                            class="custom_button mt-auto"
                            type="primary"
                            ghost>
                            Добавить еще программу
                        </a-button>
                    </div>
                    <div class="row">
                        <a-button
                            class="custom_button mt-auto"
                            type="primary"
                            block
                            ghost>
                            Прикрепите файл с пояснительной запиской к «Заявке на изменение планов финансирование по платежам»
                        </a-button>
                    </div>
                    <div class="row">
                        <a-form-model-item 
                            class="form_item"
                            prop="format"
                            label="Прикреплённые файлы">
                            <div class="grid_cols_3">
                                <div class="custom_input">File</div>
                                <div class="custom_input">File</div>
                                <div class="custom_input">File</div>
                            </div>
                        </a-form-model-item>
                    </div>

                    <div class="row">
                        <a-form-model-item 
                            class="form_item"
                            prop="format"
                            label="Укажите должность ответственного за формирование отчета">
                            <a-input
                                class="custom_input"
                                v-model="form.format"
                                placeholder="Должность ответственного за формирование отчета" />
                        </a-form-model-item>
                    </div>
                </div>
            </a-form-model>
            
            <a-button
                class="custom_button text-base"
                type="primary">
                Добавить отчет
            </a-button>
            <a-button
                class="custom_button text-base ml-2"
                type="ui">
                Отменить
            </a-button>
        </div>
        <!-- <div class="drawer_footer">
            <a-button 
                @click="closeDrawer"
                ghost
                class="ml-1"
                type="ui">
                Отменить
            </a-button>
        </div> -->
    </a-drawer>
</template>

<script>
import ConsolidationTable from '../ConsolidationTable.vue'
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
export default {
    name: "CreateFinancialOrderDrawer",
    components: {
        ConsolidationTable
    },
    props: {
        zIndex: {
            type: Number,
            default: 1010
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
            isMobile: state => state.isMobile
        }),
        drawerWidth() {
            const offset = 50
            if(this.windowWidth > 1100 + offset)
                return 1100
            return '100%'
        },
    },
    data() {
        return {
            visible: false,
            form: {
                format: ''
            },
            rules: {
                format: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
        eventBus.$on('open_create_financial_order_drawer', () => {
            this.openDrawer()    
        })
    },
    beforeDestroy() {
        eventBus.$off('open_create_financial_order_drawer')
    },
    methods: {
        openDrawer() {
            this.visible = true
        },
        closeDrawer() {
            this.visible = false
        },
        afterVisibleChange(visible) {
            if(!visible) {
            }
        },
    },
}
</script>

<style lang="scss" scoped>
// PANEL
.panel {
    padding: 30px;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
}
.panel + .panel {
    margin-top: 20px;
}
.panel_header {
    margin-bottom: 30px;
}
.panel_title {
    font-size: 1.2rem;
    line-height: 1;
    color: #000;
}
.panel_description {
    font-size: 1rem;
    color: hsla(0, 0%, 0%, 0.6);
}
.panel_step {
    font-size: 1.1rem;
    color: hsla(0, 0%, 0%, 0.3);

}
// END PANEL
// ############################################


// FORM
.form {
    &::v-deep {
        .ant-form-item {
            margin: 0;
            padding: 0;
        }
    }
}
.form_item::v-deep {
    .ant-form-item-label label {
        color: hsl(0, 0%, 0%, 0.6);
    }
}
.grid_cols_2,
.grid_cols_3 {
    display: grid;
    gap: 30px;
}
.grid_cols_2 {
    grid-template-columns: repeat(2, 1fr);
}
.grid_cols_3 {
    grid-template-columns: repeat(3, 1fr);
}

.row:not(:last-child) {
    margin-bottom: 20px;
}
// END FORM
// ############################################


// BUTTONS
.custom_input {
    height: 50px;
    padding: 5px 12px;
    border-radius: 0.25rem;
}
.custom_button {
    height: 50px;
    padding: 0 40px;
    border-radius: 0.25rem;
}
// END BUTTONS

.drawer{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
            padding: 0px;
        }
        .ant-drawer-header{
            padding-left: 20px;
            padding-right: 20px;
            @media (max-width: 900px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .ant-drawer-body{
            $header-height: 40px;
            height: calc(100% - $header-height);
            padding: 0px;
        }
        .drawer_body{
            height: calc(100%);
            padding: 35px 30px;
            
            overflow-y: auto;
            overflow-x: hidden;
            @media (max-width: 900px) {
                padding: 15px;
            }
        }
        // .drawer_footer{
        //     display: flex;
        //     align-items: center;
        //     height: 40px;
        //     border-top: 1px solid #e8e8e8;
        //     padding-left: 20px;
        //     padding-right: 20px;
        //     @media (max-width: 900px) {
        //         padding-left: 15px;
        //         padding-right: 15px;
        //     }
        // }
    }
}
</style>